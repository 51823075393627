<template>
  <ui-list
    :disableXScroll="false"
    :heading="heading"
    :headings="[
      $t('fields.date'),
      $tc('models.group', 1),
      $t('fields.available'),
      $t('language.language'),
      $t('fields.location'),
      $t('fields.actions'),
    ]"
    :sort="[
      { prop: 'date', type: false },
      undefined,
      { prop: 'participants', type: false },
      undefined,
      { prop: 'location', type: false },
      
    ]"
    :items="sessions"
    :search_selectors="['date']"
  >
    <template v-slot:default="props">
      <template>
        <ui-list-data class="list_data"
          v-if="
            $d(new Date(props.item.startDateTime), 'fullShort') === $d(new Date(props.item.endDateTime), 'fullShort')
          "
          :text="`
                  ${$d(new Date(props.item.startDateTime), 'fullShort', $i18n.locale === 'no' ? 'no' : 'en')}
              `"
        ></ui-list-data>
        <ui-list-data class="list_data"
          v-if="
            $d(new Date(props.item.startDateTime), 'fullShort') != $d(new Date(props.item.endDateTime), 'fullShort')
          "
          :text="`
                  ${$d(new Date(props.item.startDateTime), 'fullShort', $i18n.locale === 'no' ? 'no' : 'en')} 
                  - 
                  ${$d(new Date(props.item.endDateTime), 'fullShort', $i18n.locale === 'no' ? 'no' : 'en')}
              `"
        ></ui-list-data>
      </template>
      <ui-list-data class="list_data"
        :text="`
              ${$d(new Date(props.item.startDateTime), 'time', $i18n.locale === 'no' ? 'no' : 'en')}
              -
              ${$d(new Date(props.item.endDateTime), 'time', $i18n.locale === 'no' ? 'no' : 'en')}`"
      ></ui-list-data>
      <template v-if="sessions">
        <ui-list-data class="list_data"
          v-if="typeof getPercentage(props.item) === 'number'"
          :text="`${Math.max(0, (props.item.progress.assignedCount - props.item.progress.completedCount))}`"
        ></ui-list-data>
        <ui-list-data class="list_data"
          v-else
          :text="$t('labels.course.no_users_assigned')"
        ></ui-list-data>
      </template>
      <ui-list-data class="list_data"
        type="tag"
        :text="props.item.language === 'en' ? $t('language.english', 'en').toString() : $t('language.norwegian', 'no').toString()"
      ></ui-list-data>

      <!-- Capitalize first letter so sorting works properly -->
      <ui-list-data class="list_data"
        type="tag"
        :text="props.item.location"
      ></ui-list-data>

      <ui-list-data class="list_data">
        <ui-link
          :disabled="!isSessionButtonEnabled(props.item)"
          @click="handleSelectedSession(props.item)"
          :type="selectedSession ? (props.item.id === selectedSession.id ? 'success' : 'default') : 'default'"
        >
          {{ getButtonText(props.item) }}
        </ui-link>
      </ui-list-data>
    </template>
  </ui-list>
</template>

<script>
import UiList from "@/components/ui/UiList";
import UiListData from "@/components/ui/UiListData";
import UiLink from "@/components/ui/UiLink";

export default {
  props: {
    heading: {
      type: String,
    },
    sessions: {
      type: Array,
      required: true,
    },
    selectedSessionInput: {
      type: Object,
      required: false,
    }
  },

  data() {
    return {
      selectedSession: null,
    };
  },

  components: {
    UiList,
    UiListData,
    UiLink,
  },

  methods: {
    /**
     * Get the text of a button. Depends upon current state of corresponding session.
     * @param session The session base final text on (e.g. if it's full or not)
     * @returns {string}
     */
    getButtonText(session) {
      // Checking if session is already selected
      const isSelectedSession = this.selectedSession && this.selectedSession.id === session.id;
      if (isSelectedSession) {
        return this.$t("misc.chosen");
      }

      // Checking if session is expired on time
      const startTimeAfterCurrentTime = new Date(session.startDateTime) < new Date();
      if (startTimeAfterCurrentTime) {
        return this.$t("feedback.expired");
      }

      // Checking if session is full
      const isFull = this.isSessionFull(session);
      if (isFull) {
        return this.$t("misc.full");
      }

      // Default option
      return this.$t("actions.choose");
    },

    /**
     * Checks if a session button can be clicked.
     * @param session The corresponding session to check for cases (e.g. if it's full)
     * @returns {boolean} true if enabled, false otherwise
     */
    isSessionButtonEnabled(session) {
      const isSelectedSession = this.selectedSession != null && this.selectedSession.id === session.id;

      // Can always un-click assigned session
      if (isSelectedSession) {
        return true;
      }

      // Check for invalid start datetime
      const beforeExpiryDate = new Date(session.startDateTime) > new Date();
      if (!beforeExpiryDate) return false;

      // Return true if available space in session, false otherwise
      return session.progress.completedCount < session.maxParticipants;
    },
    isSessionFull(session) {
      return session.progress.completedCount >= session.maxParticipants;
    },
    handleSelectedSession(session) {
      if (this.selectedSession != null) {
        // Re-compute lost participant
        this.selectedSession.progress.completedCount--;
        this.selectedSession.progress.progressPercentage = this.computeNewPercentage(this.selectedSession);
      }

      const alreadySelected = this.selectedSession && this.selectedSession.id === session.id;
      if (alreadySelected) {
        this.selectedSession = null;
        this.$emit("selectSession", null);
      } else {
        session.progress.completedCount++;
        session.progress.progressPercentage = this.computeNewPercentage(session);
        this.selectedSession = session;
        this.$emit("selectSession", session);
      }
    },
    getPercentage(session) {
      if (session.progress && session.progress.progressPercentage) {
        return session.progress.progressPercentage;
      } else {
        if (session.assignedCount && session.assignedCount !== 0 && session.completedCount) {
          return Math.floor((session.completedCount / session.assignedCount) * 100);
        } else {
          return 0;
        }
      }
    },
    computeNewPercentage(session) {
      if (session.progress.assignedCount && session.progress.completedCount) {
        if (session.progress.assignedCount === 0) return 0;
        return Math.floor((session.progress.completedCount / session.progress.assignedCount) * 100);
      } else {
        return 0;
      }
    },
  },

  async created() {
    if (!(this.selectedSessionInput === null)) {
      this.selectedSession = this.selectedSessionInput;
    }

    // Create uppercase locations to ensure correct sorting
    this.sessions.forEach((session) => {
      session.location = session.location ? session.location[0].toUpperCase() + session.location.slice(1) : session.location;
    });
  },
};
</script>

<style scoped>
.list_data {
  padding: 10px 24px;
}
</style>
