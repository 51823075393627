<template>
  <div v-loading="loading">
    <div class="min-h-screen app_page">
      <div class="container mx-auto">
        <div class="px-3 sm:px-4 py-6 sm:py-8">
          <!-- Show after user submits -->
          <div v-if="showConfirmationText">
            <ui-card class="ui_card overflow-visible">
              <ui-header :heading="$t('registration.your_place_is_reserved').toString()">
                <app-header-locale use-colored-flags class="block w-56" />
              </ui-header>
              <p>
                {{
                  $t("registration.confirmation_text", {
                    startDateTime: getFormattedSelectedSessionStartTime(),
                    location: getSelectedSessionLocation(),
                    title: `'${courseEvent.title}'`,
                  })
                }}
              </p>
              <br />
              <p>
                {{
                  $t("registration.soon_receive_email")
                }}
              </p>
            </ui-card>
          </div>

          <div v-else-if="courseEvent">
            <ui-card class="ui_card">
              <ui-header :heading="courseEvent.title">
                <app-header-locale use-colored-flags class="block w-56" />
              </ui-header>
              <RichTextField
                  class="mb-3"
                  v-model="courseEvent.description"
                  :hideBorder="true"
                  :showEditor="false">
              </RichTextField>

              <!-- Input name, email etc. -->
              <validation-observer
                ref="observer"
                tag="form"
                @submit.prevent="handleSubmit"
                autocomplete="off"
              >
                <!-- Firstname + lastname -->
                <div class="flex flex-row flex-wrap gap-x-3 justify-between mt-6">
                  <ui-form-field
                    :label="$t('fields.first_name')"
                    rules="required"
                    class="flex-grow"
                    :minimize="true"
                  >
                    <el-input
                      type="text"
                      :placeholder="$t('placeholders.first_name')"
                      v-model="firstName"
                    ></el-input>
                  </ui-form-field>
                </div>
                <div class="flex flex-row flex-wrap gap-x-3 justify-between">
                  <ui-form-field
                    :label="$t('fields.last_name')"
                    rules="required"
                    class="flex-grow"
                    :minimize="true"
                  >
                    <el-input
                      type="text"
                      :placeholder="$t('placeholders.last_name')"
                      v-model="lastName"
                    ></el-input>
                  </ui-form-field>
                </div>

                <div class="flex flex-row flex-wrap gap-x-3 justify-between">
                  <ui-form-field
                    :label="$t('fields.email')"
                    class="flex-grow"
                    :minimize="true"
                    rules="required|email"
                  >
                    <el-input
                      type="email"
                      :placeholder="$t('placeholders.email')"
                      v-model="sessionParticipant.email"
                    ></el-input>
                  </ui-form-field>
                </div>
                <div class="flex flex-row flex-wrap gap-x-3 justify-between">
                  <ui-form-field
                    v-if="courseEvent.field1"
                    :label="courseEvent.field1"
                    rules="required"
                    class="flex-grow"
                    :minimize="true"
                  >
                    <el-input
                      type="text"
                      v-model="sessionParticipant.field1"
                    ></el-input>
                  </ui-form-field>

                  <ui-form-field
                    v-if="courseEvent.field2"
                    :label="courseEvent.field2"
                    rules="required"
                    class="flex-grow"
                    :minimize="true"
                  >
                    <el-input
                      type="text"
                      v-model="sessionParticipant.field2"
                    ></el-input>
                  </ui-form-field>
                </div>
              </validation-observer>

              <p class="font-bold text-lg mt-3" v-if="sessions.length === 0">
                {{ `${$t('placeholders.no_sessions_registered_on_course')}` }}
              </p>
              <CourseEventRegistrationList v-else
                :session="courseEvent"
                @selectSession="(session) => handleSelectSession(session)"
                :heading="$tc('models.group', 2)"
                :sessions="sessions"
              />

              <div class="flex mt-5 flex-row justify-end">
                <ui-link
                  type="primary"
                  :disabled="!canSubmit || !allFieldsFilled"
                  @click="handleSubmit()"
                >
                  {{ `${$t("actions.save")}` }}
                </ui-link>
              </div>
            </ui-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiHeader from "@/components/ui/UiHeader";
import UiLink from "@/components/ui/UiLink";
import UiCard from "@/components/ui/UiCard";
import UiFormField from "@/components/ui/UiFormField";
import CourseEventRegistrationList from "@/app/participant/components/CourseEventRegistrationList.vue";
import {getCourseEvent, postParticipation, isEmailRegisteredInGroup} from "../api";
import AppHeaderLocale from "@/components/app/AppHeaderLocale";
import RichTextField from "@/components/form/RichTextField.vue";

export default {
  data() {
    return {
      firstName: null,
      lastName: null,
      sessionParticipant: {
        session: null,
        name: null,
        email: null,
        field1: null,
        field2: null,
      },
      courseEvent: null,
      sessions: [],
      showConfirmationText: false,
      loading: false
    };
  },

  components: {
    RichTextField,
    UiHeader,
    UiLink,
    UiCard,
    UiFormField,
    CourseEventRegistrationList,
    AppHeaderLocale,
  },

  watch: {
    firstName(_, __) {
      this.sessionParticipant.name = this.name;
    },
    lastName(_, __) {
      this.sessionParticipant.name = this.name;
    },
  },

  computed: {
    /** Denotes if a warning for email validation should be shown */
    showEmailValidationWarning() {
      if (!this.hasWrittenEntireEmail) return false;
      return !this.isEmailValid;
    },
    allFieldsFilled() {
      let usingField1 = this.courseEvent && this.courseEvent.field1;
      let usingField2 = this.courseEvent && this.courseEvent.field2;

      let fields = this.firstName && this.lastName && this.sessionParticipant.email;
      
      // Validate extra fields too
      if (usingField1) {
        fields = fields && this.sessionParticipant.field1;
      }
      if (usingField2) {
        fields = fields && this.sessionParticipant.field2;
      }

      return fields ? true : false;
    },
    canSubmit() {
      // User has chosen a session
      return this.sessionParticipant.session != null;
    },
    name() {
      if (this.firstName && this.firstName !== "") return this.firstName + " " + this.lastName;
      return "";
    },
  },

  methods: {
    getFormattedSelectedSessionStartTime() {
      const startDateTime = new Date(this.findSessionById(this.sessionParticipant.session).startDateTime);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return startDateTime.toLocaleString(this.$i18n.locale, options);
    },
    getSelectedSessionLocation() {
      return this.findSessionById(this.sessionParticipant.session).location;
    },
    findSessionById(id) {
      return this.sessions.find((s) => s.id === id);
    },
    handleSelectSession(val) {
      if (val == null) this.sessionParticipant.session = null;
      else this.sessionParticipant.session = val.id;
    },

    async handleSubmit() {
      const isFormValid = await this.isFormValid();
      const hasSelectedSession = this.sessionParticipant.session != null;

      // Check fields entered
      if (!isFormValid || !hasSelectedSession) {
        this.$message({ message: this.$t("validation.check_fields").toString(), type: "error" });
        return;
      }

      // Check for duplicate emails within group
      const duplicateEmail = await isEmailRegisteredInGroup(this.sessionParticipant.session, this.sessionParticipant.email);
      if (duplicateEmail) {
        this.$message.error({ message: this.$t("validation.email_already_registered_in_session") });
        return;
      }
      
      // Save registration
      const response = await postParticipation(this.sessionParticipant);
      if (response && response.status === 200) {
        this.$message({ message: this.$t("server_feedback.status_200_saved").toString(), type: "success" });
        this.showConfirmationText = true;
      }
    },

    /**
     * Checks whether this form is valid (required fields + chosen a session)
     * @returns {Promise} true if form valid, false otherwise
     */
    async isFormValid() {
      if (!this.isSessionSelected()) return false;

      // Validate the form fields
      return await this.$refs.observer.validate();
    },
    /**
     * Checks whether a session is selected.
     * @returns {boolean} true if session selected, false otherwise
     */
    isSessionSelected() {
      return this.sessionParticipant.session != null;
    },

    async getCourseEvent() {
      this.loading = true;
      const courseEvent = await getCourseEvent(this.$route.params.id);
      this.courseEvent = courseEvent;
      this.sessions = courseEvent.sessions;
      this.loading = false;
    },
  },

  async created() {
    this.loading = true;
    await this.getCourseEvent();
    this.loading = false;
  },
};
</script>

<style scoped>
/** Fixing wrong margin on p, and showing lists */
:deep(.ProseMirror) p {
  margin-bottom: 0px;
}
:deep(.ProseMirror) ol {
  list-style-type: decimal;
  margin-left: 20px;
}
:deep(.ProseMirror) ul {
  list-style-type: disc;
  margin-left: 20px;
}

.fieldInfoText {
  color: red;
  font-size: 10pt;
}

.ui_card {
  margin-bottom: 10px;
}

.app_page {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
